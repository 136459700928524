.App {
  background-color: white;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

@media (min-width: 650px) {
  .App {
    font-size: 16px;
  }
}
