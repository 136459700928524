.TopBar {
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #ccc;
  display: flex;
  height: 40px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
}

.TopBar-nav {
  cursor: pointer;
  text-align: center;
}

.TopBar-nav:hover {
  color: orange;
}

.TopBar-navIcon {
  font-size: 20px;
  line-height: 0.7;
}

.TopBar-leftNav {
  padding-left: 10px;
}

.TopBar-rightNav {
  padding-right: 10px;
}

.TopBar-title {
  font-size: 18px;
  margin: 0;
  text-align: center;
}

@media (min-width: 650px) {
  .TopBar {
    height: 60px;
  }

  .TopBar-title {
    font-size: 32px;
  }

  .TopBar-navIcon {
    font-size: 30px;
    line-height: 0.7;
  }

  .TopBar-leftNav {
    padding-left: 20px;
  }

  .TopBar-rightNav {
    padding-right: 20px;
  }
}

@media (min-width: 900px) {
  .TopBar {
    width: 900px;
  }
}
