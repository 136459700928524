.Recipe {
  margin-top: 40px;
  padding-top: 1px; /* Weird hack that works */
}

.Recipe-JSONView {
  border-top: 1px solid #ccc;
  padding-bottom: 0.1px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.Recipe-JSONView-warning {
  background-color: orange;
}

.Recipe-JSONView-pre {
  background-color: #ebebeb;
  overflow-x: auto;
}

@media (min-width: 650px) {
  .Recipe {
    margin-top: 60px;
  }
}
