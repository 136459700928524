.RecipeListItem {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
}

.RecipeListItem:hover {
  background-color: #eee;
  font-style: italic;
}

.RecipeListItem-right {
  padding-right: 15px;
}

.RecipeListItem-warning {
  color: orange;
  font-size: 32px;
}

.RecipeListItem-title {
  margin: 0;
}

.RecipeListItem-divider {
  margin: 0;
}
