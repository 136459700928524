.RecipeList {
  margin-top: 40px;
  padding: 10px;
}

.RecipeList-searchInput {
  border: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  font-size: inherit;
  width: 100%;
}

.RecipeList-searchInput:focus {
  outline: none;
}

.RecipeList-clearSearch {
  float: right;
}

@media (min-width: 650px) {
  .RecipeList {
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
