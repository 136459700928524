.RecipeView {
  padding-left: 10px;
  padding-right: 10px;
}

.RecipeView-li {
  line-height: 1.3;
  padding-bottom: 7px;
}

.RecipeView-column {
  width: 100%;
}

@media (min-width: 650px) {
  .RecipeView {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
  }

  .RecipeView-column {
    width: 50%;
  }
}
