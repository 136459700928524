@import 'https://fonts.googleapis.com/css?family=Roboto';

/**
 * Body CSS
 */
html,
body {
  background-color: #eee;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  margin-bottom: 50px;
}

b {
  text-decoration: underline;
}

