.Inputs {
  background-color: #ebebeb;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.input {
  background-color: inherit;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-size: inherit;
}

.input:focus {
  outline: none;
}

@media (min-width: 650px) {
  .Inputs {
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
